import { render, staticRenderFns } from "./recordsDetails.vue?vue&type=template&id=b4ac7836&scoped=true&"
import script from "./recordsDetails.vue?vue&type=script&lang=js&"
export * from "./recordsDetails.vue?vue&type=script&lang=js&"
import style0 from "./recordsDetails.vue?vue&type=style&index=0&id=b4ac7836&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4ac7836",
  null
  
)

export default component.exports