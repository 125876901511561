<template>
  <div class="container">
    <div class="block-title">报修流程</div>
    <van-steps direction="vertical" :active="repairStep(detailInfo.propertyStatus)">
      <van-step>
        <div>提交报修申请</div>
        <div class="status" v-if="detailInfo.propertyStatus == -1">
          <p>已撤销</p>
          <p>{{ detailInfo.updateTime }}</p>
        </div>
        <div class="status" v-else>
          <p>提交成功</p>
          <p>{{ detailInfo.updateTime }}</p>
        </div>
      </van-step>
      
      <template v-if="detailInfo.propertyStatus != -1">
        <van-step>
          <div>物业分配</div>
          <div class="status">
            <p>{{ propertyAudit(detailInfo.propertyStatus) }}</p>
            <p>{{ detailInfo.auditTime }}</p>
          </div>
          <div class="reason" v-if="detailInfo.caption">分配失败说明: {{ detailInfo.caption }}</div>
        </van-step>
      </template>
      <template v-if="detailInfo.propertyStatus >= 1">
        <van-step>
          <div>维修状态</div>
          <div class="status">
            <p>{{ maintenanceState(detailInfo.propertyStatus) }}</p>
            <p>{{ detailInfo.statusTime }}</p>
          </div>
        </van-step>
        <van-step>
          <div>维修评价</div>
          <div class="status">
            <p>{{ evaluate(detailInfo.evaluationStatus) }}</p>
            <p>{{ detailInfo.feedbackTime }}</p>
          </div>
        </van-step>
      </template>
    </van-steps>
    <!-- <div v-if="isLoading" class="loading">
      <van-loading color="#fff" size="28px" vertical>加载中...</van-loading>
    </div> -->
    <div class="block-title">报修信息</div>
    <van-cell-group class="cell-group">
      <van-cell title="报修类型">
        <template #right-icon>
          <span class="address">{{ detailInfo.repairType == 8001 ? '公共报修' : '个人报修' }}</span>
        </template>
      </van-cell>
      <van-cell title="报修项目">
        <template #right-icon>
          <span class="price-month">{{ detailInfo.repairItem }}</span>
        </template>
      </van-cell>
      <van-cell title="报修地点">
        <template #right-icon>
          <span class="price-time">{{ detailInfo.address }}</span>
        </template>
      </van-cell>
      <van-cell title="报修描述">
        <template #right-icon>
          <span class="price">{{ detailInfo.remark }}</span>
        </template>
      </van-cell>
      <van-cell title="预约时间" v-if="detailInfo.repairType == 8002">
        <template #right-icon>
          <span class="odd">{{ detailInfo.orderTime }}</span>
        </template>
      </van-cell>
      <van-cell title="联系人">
        <template #right-icon>
          <span class="time">{{ detailInfo.ownerName }}</span>
        </template>
      </van-cell>
      <van-cell title="联系电话">
        <template #right-icon>
          <span class="time">{{ detailInfo.ownerPhone }}</span>
        </template>
      </van-cell>
      <van-cell title="">
        <template #right-icon>
          <div class="wrap-img">
            <div>报修照片</div>
            <span v-if="imgList.length == 0">无</span>
            <div v-else>
              <van-image 
                v-for="(item, index) in imgList"
                :src="item"
                :key="item"
                fit="cover" 
                @click="imagePreviewWarn(index)" />
            </div>
          </div>
        </template>
      </van-cell>

      <van-cell title="" v-if="detailInfo.propertyStatus >= 2">
        <template #right-icon>
          <div class="cell-row">
            <div>维修评价</div>
            <div class="evaluate-box">
              <div class="item" :class="{'act': actEvaluate == item.key}" v-for="(item,index) in evaluateList" :key="index" @click="toggle(item.key)">{{ item.value }}</div>
            </div>
          </div>
        </template>

      </van-cell>
    </van-cell-group>
    <!-- 分配中 -->
    <div class="btns" v-if="detailInfo.propertyStatus == 0">
      <van-button type="info" block @click="changeMes" >修改</van-button>
      <van-button type="danger" block @click="removeFunc" :loading="subLoading">撤销</van-button>
    </div>
    <!-- 分配失败 -->
    <div class="btns" v-else-if="detailInfo.propertyStatus == -2">
      <van-button type="danger" block @click="changeMes" :loading="subLoading">重新提交</van-button>
    </div>
    <!-- 维修完成 待评价 -->
    <div class="btns" v-else-if="detailInfo.propertyStatus >= 2 && detailInfo.evaluationStatus != 2">
      <van-button block type="info" @click="subEvaluate" :loading="subLoading">提交评价</van-button>
    </div>
  </div>
</template>
<script>
import { repairDetail, repairRemove, setEvaluate } from '@/api/repairApi'
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      id: '',
      // isLoading: true,
      detailInfo: {},
      imgList: [],
      subLoading: false,
      actEvaluate: '',
      evaluateList: [
        {
          key: 1,
          value: '非常满意',
        },
        {
          key: 2,
          value: '满意',
        },
        {
          key: 3,
          value: '一般',
        },
        {
          key: 4,
          value: '不满意',
        }
      ]
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetails()
  },
  computed: {
    // 物业分配状态
    propertyAudit() {
      return status => {
        switch (status) {
          case 0:
            return '分配中'
          case 1:
            return '分配通过'
          case -2:
            return '分配失败'
          default:
            return '分配中'
        }
      }
    },
    // 维修状态
    maintenanceState() {
      return status => {
        switch (status) {
          case 0:
            return '待维修'
          case 1:
            return '维修中'
          case 2:
            return '维修完成'
          default:
            return '待维修'
        }
      }
    },
    // 维修评价
    evaluate() {
      return status => {
        switch (status) {
          case 1:
            return '待评价'
          case 2:
            return '已评价'
          default:
            return '待评价'
        }
      }
    },
    // 维修进度(进度高亮)
    repairStep() {
      return status => {
        if(status == -1 || status == 0) {
          return 0
        } else if(status == -2) {
          return 1
        } else if(status == 1 || status == 2 && !this.detailInfo.evaluationStatus) {
          return 2
        } else {
          return 3
        }
      }
    },
  },
  methods: {
    imagePreviewWarn(index){
      ImagePreview({
        images: this.imgList,
        startPosition: index,
      });
    },
    toggle(key) {
      if(this.detailInfo.evaluationStatus != 2) {
        this.actEvaluate = key
      }
    },
    subEvaluate() {
      if(!this.actEvaluate) {
        return this.$toast({
          type: 'error',
          message: '请选择评价',
          duration: 500,
        })
      }
      this.subLoading = true
      setEvaluate({
        id: this.id,
        feedback: this.actEvaluate,
      }).then(res => {
        this.subLoading = false
        if(res.code == 200) {
           this.$toast({
            type: 'success',
            message: '操作成功',
            duration: 500,
          })
          this.getDetails()
        }
      })
    },
    // 修改按钮
    changeMes() {
      const { id } = this
      const { repairType } = this.detailInfo
      // const openid = this.$store.getters.openId;

      this.$router.push(`/propertyRepair?id=${id}&repairType=${repairType}`)
    },
    getDetails() {
      let req = {
        id: this.id,
        openid: this.$store.getters.openId,
      }
      this.$store.commit('SET_IS_LOADING', true)
      repairDetail(req).then(res => {
        if (res.code == 200) {
          this.detailInfo = res.data
          // 已评价回显评价内容
          this.actEvaluate = res.data.evaluationStatus == 2 ?res.data.feedback:''
          if (this.detailInfo.ownerPic) {
            this.imgList = this.detailInfo.ownerPic.split(',')
          }
          // this.isLoading = false
          this.$store.commit('SET_IS_LOADING', false)
        } else {
          return this.$toast({
            type: 'error',
            message: res.msg,
            duration: 500,
          })
        }
      })
    },
    removeFunc() {
      let req = {
        id: this.id,
      }
      repairRemove(req).then(res => {
        if (res.code == 200) {
          let _this = this
          return this.$toast({
            type: 'success',
            message: '撤销成功',
            duration: 500,
            onClose: function () {
              // _this.$router.push({
              //   path: '/home',
              // })
              _this.$router.go(-1)
            },
          })
        } else {
          return this.$toast({
            type: 'error',
            message: res.msg,
            duration: 500,
          })
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0 20px;

  .status {
    display: flex;
    justify-content: space-between;
  }

  .wrap-img {
    width: 100%;
    .van-image {
      width: 33.33%; 
    }
  }
  .cell-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.loading {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 20;
  padding-top: 5.333333rem;
  font-size: 28px;
  background-color: rgba(0, 0, 0, 0.5);
}
.van-step--vertical:not(:last-child)::after {
  border-bottom-width: 0px;
}
.tab {
  height: 20px;
  border-left: 2px solid #4197fb;
  margin-top: 20px;
  padding: 1px 8px 0;
}
.row {
  display: flex;
  line-height: 30px;
  &.first {
    padding-top: 20px;
  }
  .label {
    width: 70px;
    text-align: right;
  }
  .info {
    width: calc(100% - 70px);
  }
}
.btns {
  display: flex;
  justify-content: space-around;
  padding-top: 30px;
  margin-bottom: 20px;
  // .van-button {
  //   width: 90px;
  // }
}
.imgCon {
  overflow-x: auto;
}
.img {
  margin-right: 10px;
}
.imgList {
  width: auto;
  display: flex;
}
.evaluate-box {
  display: flex;
  align-items: center;
  .item {
    padding: 2px 6px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 5px;
  }
  .item.act {
    border-color: #2eb1fc;
    background-color: #1989fa;
    color: #fff;
  }
  & .item:last-child {
    margin-right: 0;
  }
}
.cell-group {
  margin-bottom: 24px;
}
</style>
